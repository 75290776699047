$bg-clr: #E4BAD4;
$btn-clr: #b3d7ff;
$yellow: #F6DFEB;
#body {
	background-color: $bg-clr;
	.navbar-light { 
		.navbar-brand {
			color: white;
			margin: 20px 40px;
			padding-bottom: 0px;
			font-size: 1.5rem;
			border-bottom: 3px solid $yellow;
		}
	}
	.Home
	{
		h1 {
			margin-top: 120px;
			width: 50%;
			font-family: 'Imprima', sans-serif;
			font-size: 4rem;
			letter-spacing: 1px;
			line-height: unset;
			word-spacing: 1px;
			.white {
				color: blue;
			}
			.yellow {
				color: $yellow;
			}
		}
		.btn {
			background-color: $yellow;
			a {
				font-weight: bold;
				color: black;
			}
			&:hover {
				box-shadow: 0 1.3rem 3rem rgba(0, 0, 0, 0.175) !important;
			}
		}
		p {
			.fab {
				margin: 0px 10px;
				&:hover {
					text-decoration: none;
				}
			}
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: 1px;
			color: black;
		}
	}
	.Art {
		.container-fluid{
			max-width: fit-content;
			padding-top: 50px;
			article {
				display: contents;
			}
			.Image-Container {
				img {
					height: 315px;
					margin: 5px;
					opacity: 1;
				}
				&:hover {
					img {
						cursor: pointer;
						opacity: 0.5;
					}
				}
			}
		}
	}
	.modal {
		.modal-dialog {
			max-width: fit-content;
			.modal-content {		
				.modal-header {
					padding: 5px;
					border-bottom: 0px solid #dee2e6;
				}
				.modal-body {
					padding: 5px;
					img {
						display: block;
						height: 626px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 479px) {
	#body {
		padding: 10px;
		.navbar-light {
			.navbar-brand {
				padding: 0px;
				margin: 0px;
				font-size: 1.2rem;
			}
		}
		.Home {
			h1 {
				margin-top: 20px;
				padding: 0px 20px;
				font-size: 1.9rem;
				word-spacing: 0px;
				width: fit-content;
			}
			p {
				font-size: 0.9rem;
			}
		}
		.Art {
			.container-fluid {
				display: flex;
				flex-direction: column;
				.Image-Container {
					img {
						height: 315px;
						margin: 5px;
						opacity: 1;
					}
					&:hover {
						img {
							cursor: pointer;
							opacity: 0.5;
						}
					}
				}
			}
		}
		.modal {
			padding: 0px;
			.modal-dialog {
				margin: 0px;
				.modal-content {
					.modal-body {
						img {
							width: 350px;
							height: 400px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#body {
		padding: 10px;
		.Home {
			h1 {
				font-size: 2.6rem;
				margin-top: 20px;
				padding: 0px 20px;
				width: fit-content;
			}
		}
		.Art {
			.container {
				.col {
					padding-left: 0px;
					padding-right: 0px;	
				}
			}
		}
		.modal {
			padding: 0px;
			.modal-dialog {
				margin: 0px;
				.modal-content {
					.modal-body {
						img {
							width: 350px;
							height: 400px;
						}
					}
				}	
			}
		}	
	}
}

